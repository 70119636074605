import React, { useEffect } from "react";
import { Icon } from '@iconify/react';
import "@lottiefiles/lottie-player";
import './overview.scss';
import accountInfo from '../../../jsons/aboutMe.json';

const Overview = () => {

    useEffect(() => {
        const glowInTexts = document.querySelectorAll(".glowIn");
        glowInTexts.forEach(glowInText => {
            const letters = glowInText.textContent.split("");
            glowInText.textContent = "";
            letters.forEach((letter, i) => {
                let span = document.createElement("span");
                span.textContent = letter;
                span.style.animationDelay = `${i * 0.02}s`;
                glowInText.append(span);
            });
        });
    }, [])

    return (
        <div>
            <div className="bg-[#ffffff15] w-full h-40 shadow-2xl rounded-xl flex justify-around lg:justify-between items-center max-h-40">
                <img className="h-[150px] w-auto rounded-xl m-1" draggable={false} alt="avatar" src="/images/avatar.png" />
                <div className="h-[140px] overflow-hidden inline-flex flex-col justify-center items-center">
                    <div className="hidden md:inline-flex xl:hidden">
                        <button class="bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded inline-flex items-center" onClick={() => window.open(accountInfo.account.resumePath, "_blank")}>
                            <Icon icon="akar-icons:cloud-download" width="20" height="20" />
                            <span className="ml-1">Download CV</span>
                        </button>
                    </div>
                    <p className="hidden md:block xl:hidden mx-3 overflow-y-auto overflow-x-hidden glowIn text-lime-50">
                        {accountInfo.account.aboutMe}
                    </p>
                </div>
                <div className="col-span-2 p-3 px-1 xl:pl-6">
                    <div className="flex flex-col xl:flex-row justify-between items-center">
                        <div className="flex flex-col items-center xl:items-start">
                            <img className="max-h-[70px] -my-2 -ml-5 w-auto rounded-xl" draggable={false} alt="name" src="/images/name.png" />
                            <p className="italic text-center text-gray-400 glowIn">{accountInfo.account.title}</p>
                        </div>
                        <div className="hidden xl:inline-flex">
                            <button class="bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded inline-flex items-center" onClick={() => window.open(accountInfo.account.resumePath, "_blank")}>
                                <Icon icon="akar-icons:cloud-download" width="20" height="20" />
                                <span className="ml-1">Download CV</span>
                            </button>
                        </div>
                        <div className="flex items-center">
                            {/* Linkedin */}
                            {Object.keys(accountInfo.contact).map((key)=>
                                <a href={`${accountInfo.contact[key]}`} style={{color: 'white'}}>
                                    <lottie-player
                                        autoplay
                                        loop
                                        src={`/lotties/${key}.json`}
                                        style={{ height: '40px', width: '40px' }}
                                    />
                                </a>
                            )}
                        </div>
                    </div>
                    <p className="mt-2 hidden xl:block glowIn text-lime-50">{accountInfo.account.aboutMe}</p>
                </div>
            </div>
            <div className="inline-flex flex-col items-end md:hidden mt-2 bg-[#ffffff15] rounded-xl p-3">
                <p className="glowIn text-lime-50">{accountInfo.account.aboutMe}</p>
                <div className="inline-flex md:hidden mt-1">
                    <button class="bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded inline-flex items-center" onClick={() => window.open(accountInfo.account.resumePath, "_blank")}>
                        <Icon icon="akar-icons:cloud-download" width="20" height="20" />
                        <span className="ml-1 text-lime-50">Download CV</span>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Overview