import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import workList from '../../../jsons/portfolios.json';

const colorList= [
    "bg-orange-600",
    "bg-rose-600",
    "bg-lime-600",
    "bg-slate-600",
    "bg-blue-500",
    "bg-stone-600",
    "bg-purple-600"
];

const WorkHistory = () => {
    return (
        <div className="bg-[#ffffff15] rounded-xl p-4 h-[500px] md:h-full overflow-scroll overflow-x-hidden">
            {
                workList.map((work, ind) => {
                    return (
                        <div className={`${ind % 2 ? "bg-[#ffffff15]": ""} rounded-xl p-2 relative`}>
                            <div className={`${colorList[ind % colorList.length]} flex items-center cursor-pointer p-1 px-5 top-4 left-4 rounded-xl absolute z-10`} onClick={() => window.open(`${work.liveLink}`, "_blank")}>
                                <p className="text-xs text-center text-lime-50 font-medium italic mr-1">{work.projectName}</p>
                            </div>
                            <Swiper
                                modules={[Navigation, Autoplay]}
                                spaceBetween={50}
                                slidesPerView={1}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                navigation
                            >
                                {work.gallery.map((imgUrl) =>(
                                    <SwiperSlide><img src={imgUrl} alt="9sseafood_1" className="rounded-xl" /></SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default WorkHistory