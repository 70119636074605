import React from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import { Icon } from '@iconify/react';
import skillsInfo from '../../../jsons/skills.json';

const Skills = () => {
    return (
        <div className="rounded-xl p-4 relative h-[500px] md:h-full overflow-scroll overflow-x-hidden">
            <div className="p-2 pt-0">
                <p className="italic text-md font-medium bg-orange-600 p-1 px-5 rounded-xl text-center flex items-center justify-center">
                    <Icon icon="eos-icons:blockchain" className="mr-2" />
                    Blockchain
                </p>
                <div className="p-3">
                    {skillsInfo.blockchain.skills.map((skill, ind) => {
                        return (
                            <>
                                <p className="text-lime-100 text-sm mt-1"> {skill} </p>
                                <ProgressBar completed="" barContainerClassName={`h-[12px] w-full bg-[#ffffff10] rounded-[50px] p-[1px]`} completedClassName={`h-[10px] w-[${skillsInfo.blockchain.marks[ind]}] bg-fuchsia-400 rounded-[50px]`} />
                            </>
                        )
                    })}
                </div>
            </div>
            <div className="p-2">
                <p className="italic text-md font-medium bg-rose-600 p-1 px-5 rounded-xl text-center flex items-center justify-center">
                    <Icon icon="clarity:display-solid-alerted" className="mr-2" />
                    Frontend
                </p>
                <div className="p-3">
                    {skillsInfo.frontend.skills.map((skill, ind) => {
                        return (
                            <>
                                <p className="text-lime-100 text-sm mt-1"> {skill} </p>
                                <ProgressBar completed="" barContainerClassName={`h-[12px] w-full bg-[#ffffff10] rounded-[50px] p-[1px]`} completedClassName={`h-[10px] w-[${skillsInfo.frontend.marks[ind]}] bg-fuchsia-400 rounded-[50px]`} />
                            </>
                        )
                    })}
               </div>
            </div>
            <div className="p-2">
                <p className="italic text-md font-medium bg-lime-600 p-1 px-5 rounded-xl text-center flex items-center justify-center">
                    <Icon icon="icon-park-solid:database-setting" className="mr-2" />
                    Backend
                </p>
                <div className="p-3">
                    {skillsInfo.backend.skills.map((skill, ind) => {
                        return (
                            <>
                                <p className="text-lime-100 text-sm mt-1"> {skill} </p>
                                <ProgressBar completed="" barContainerClassName={`h-[12px] w-full bg-[#ffffff10] rounded-[50px] p-[1px]`} completedClassName={`h-[10px] w-[${skillsInfo.backend.marks[ind]}] bg-fuchsia-400 rounded-[50px]`} />
                            </>
                        )
                    })}
                </div>
            </div>
            <div className="p-2">
                <p className="italic text-md font-medium bg-blue-600 p-1 px-5 rounded-xl text-center flex items-center justify-center">
                    <Icon icon="bi:tools" className="mr-2" />
                    Tools & Knowledges
                </p>
                <div className="p-3">
                    {skillsInfo.knowledges.skills.map((skill, ind) => {
                        return (
                            <>
                                <p className="text-lime-100 text-sm mt-1"> {skill} </p>
                                <ProgressBar completed="" barContainerClassName={`h-[12px] w-full bg-[#ffffff10] rounded-[50px] p-[1px]`} completedClassName={`h-[10px] w-[${skillsInfo.knowledges.marks[ind]}] bg-fuchsia-400 rounded-[50px]`} />
                            </>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Skills