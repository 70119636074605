import React from "react";
import { Icon } from "@iconify/react";
import experienceInfo from "../../../jsons/experiences.json";

const colorList = [
  "text-orange-600",
  "text-blue-600",
  "text-purple-600",
  "text-lime-600"
]

const Projects = () => {
  return (
    <div className="bg-[#ffffff15] rounded-xl p-4 h-[500px] md:h-full overflow-scroll overflow-x-hidden">
      <p className="text-md italic bg-violet-600 p-1 font-medium w-1/2 rounded-xl text-center flex items-center justify-center">
        <Icon icon="cil:education" className="mr-2" />
        Education
      </p>
      {experienceInfo.educations.map((edu) => (
        <div className="flex items-start">
          <div className="ml-10 mr-3 mt-3 w-full">
            <p className="text-xl font-bold text-green-500">
              {edu.school_name}
            </p>
            <div className="flex items-center justify-between">
              <p className="text-md italic text-gray-400">{edu.title}</p>
              <p className="text-md italic text-gray-400">{`${edu.start_date} - ${edu.end_date}`}</p>
            </div>
            <ul className="list-disc ml-5 mt-1">
              {edu.notes.map((note) => (
                <li className="text-sm">{note}</li>
              ))}
            </ul>
          </div>
        </div>
      ))}
      <p className="text-md italic bg-pink-600 p-1 font-medium w-1/2 rounded-xl text-center mt-5 flex items-center justify-center">
        <Icon icon="ic:baseline-work" className="mr-2" />
        Experience
      </p>
      {
        experienceInfo.companies.map((company, ind) => {
          return (
            <div className="flex items-start">
              <div className="ml-10 mr-3 mt-3 w-full">
              <p className={`text-xl font-bold ${colorList[ind % colorList.length]}`}>{company.companyName}</p>
                <div className="flex items-center justify-between">
                  <p className="text-md italic text-gray-400">{company.title}</p>
                  <p className="text-md italic text-gray-400">{company.startDate} - {company.endDate}</p>
                </div>
                <ul className="list-disc ml-5 mt-1">
                  {company.contents.map((content) =>
                    <li className="text-sm">
                      {content}
                    </li>
                  )}
                  <li className="text-sm">
                    I have integrated MongoDB into backend server.
                  </li>
                  <li className="text-sm">
                    I imagined, created and implemented a new delivery system.
                  </li>
                </ul>
              </div>
            </div>
          )
        })
      }

    </div>
  );
};

export default Projects;
